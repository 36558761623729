@font-face {
  font-family: 'Averta';
  src: url('assets/fonts/Averta-Regular.woff') format('woff');
  /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'AvertaLight';
  src: url('assets/fonts/Averta-Light.woff') format('woff');
  /* Pretty Modern Browsers */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Averta', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #131e29;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #ff4731;
  text-decoration: underline;
}
